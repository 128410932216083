import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import StripeCheckoutForm from './StripeForm';
import { getAuth } from 'firebase/auth';

const db = getFirestore();
const auth = getAuth();

const stripePromise = loadStripe(process.env.REACT_APP_API_KEY_PUBLIC_STRIPE);

const StripeWrapper = (props) => {
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        console.log('Fetching client secret...');
        const response = await axios.post('https://europe-west1-ziel-studienkolleg.cloudfunctions.net/StripePayment/create-payment-intent', {
          amount: props.amount * 100, // Сумма в центах
          currency: props.currency,
          userId: auth.currentUser.uid, // передаем userId
          items: props.items
        });

        setClientSecret(response.data.clientSecret);

        await handleFirebase(props, response.data.clientSecret);
      } catch (axiosError) {
        console.error("Error creating payment intent:", axiosError);
      }
    };

    fetchClientSecret();
  }, [props]);

  const handleFirebase = async (props, clientSecret) => {
    try {
      const userId = auth.currentUser.uid;
      const receiptRef = doc(db, 'Users', userId, 'Receipts', clientSecret); // Используем 4 сегмента

      const receiptData = {
        TokenId: null,
        Amount: props.amount,
        Currency: props.currency,
        UserId: userId,
        Promo: props.description || null,
        Items: props.items || null,
        Gateway: "stripe",
        Status: 100,
        PurchaseDate: new Date(),
        Origin: props.origin || null,
        Successful: false,
      };

      // Создаем или обновляем документ в Firestore
      await setDoc(receiptRef, receiptData);

      console.log('Receipt added to Firebase');
    } catch (firebaseError) {
      console.error("Error updating Firebase:", firebaseError);
    }
  };

  const options = {
    clientSecret: clientSecret,
  };

  return clientSecret ? (
    <Elements stripe={stripePromise} options={options}>
      <StripeCheckoutForm {...props} clientSecret={clientSecret} />
    </Elements>
  ) : (
    <div>Loading...</div>
  );
};

export default StripeWrapper;











