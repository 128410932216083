import React, { Component } from 'react';
import PurchaseList from '../Userprofile/Purchaselist';
import Menu from '../Sidepanel/Leftmenu';
import AliceCarousel from 'react-alice-carousel';
import { getFirestore, collection, doc, getDoc } from 'firebase/firestore';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../../index.css';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import Header from '../Style/Header';
import Footer from '../Style/Footer';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ShopItem from '../Shop/Shopitem';
import Completion from '../Shop/Completion';
import Kart from '../Shop/ShopCart';
import SupportForm from '../Support/Supportform';
import androidLogoImage from '../../images/getongp.svg';
import phoneStock from '../../images/phone_stock.png';
import appscreen from '../../images/iframe1.png';
import appscreen1 from '../../images/iframe2.png';
import appscreen2 from '../../images/iframe3.png';
import appscreen3 from '../../images/iframe4.png';
import appscreen4 from '../../images/iframe5.png';
import appscreen5 from '../../images/iframe6.png';
import appscreen6 from '../../images/iframe7.png';
import appscreen7 from '../../images/iframe8.png';
import iosLogoImage from '../../images/getonas.svg';
import { GoogleAuthProvider, EmailAuthProvider, FacebookAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';

const db = getFirestore();

export default class ConnectUI extends Component {
  state = {
    isSignedIn: false,
    totalNumber: 0,
    examNumber: 0,
    themesNumber: 0
  };

  async fetchData() {
    const docRef = doc(collection(db, 'Aufgaben'), 'Insgesamt');
    const snapshot = await getDoc(docRef);
    return snapshot.data();
  }

  componentDidMount() {
    this.fetchData()
      .then((result) => {
        this.setState({
          totalNumber: result.Total,
          examNumber: result.Exam,
          themesNumber: result.Themes,
        });
      })
      .catch((error) => {
        console.error('Error fetching document: ', error);
      });

    this.unregisterAuthObserver = getAuth().onAuthStateChanged((user) =>
      this.setState({ isSignedIn: !!user })
    );

    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(getAuth());
    }

    ui.start('#firebaseui-auth-container', this.uiConfig);
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  uiConfig = {
    signInSuccessUrl: '/shop',
    signInOptions: [
      EmailAuthProvider.PROVIDER_ID,
      FacebookAuthProvider.PROVIDER_ID,
    ],
    privacyPolicyUrl: function () {
      window.location.assign(
        'https://firebasestorage.googleapis.com/v0/b/ziel-studienkolleg.appspot.com/o/Documents%2FNutzungsvertrag_der_Plattform.pdf?alt=media&token=9e68fe40-f9b0-4e4e-9935-6ee5e7b58102'
      );
    },
    tosUrl: function () {
      window.location.assign('https://firebasestorage.googleapis.com/v0/b/ziel-studienkolleg.appspot.com/o/Documents%2FDatenschutzerkl_228_rung.pdf?alt=media&token=df5d15df-ac6c-4883-9396-4f497f6f864b');
    },
    callbacks: {
      signInSuccessWithAuthResult: () => false, // Avoid redirects after sign-in.
    },
  };

  signInWithGoogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        this.setState({ isSignedIn: true });
      })
      .catch((error) => {
        console.error('Error during sign-in:', error);
      });
  };

  Logout = () => {
    return getAuth().signOut().then(() => {
      this.setState({ isSignedIn: false });
    });
  };

  render() {
    const { isSignedIn, totalNumber, examNumber, themesNumber } = this.state;

    if (!isSignedIn) {
      return (
        <div className="test">
          <div>
            <Header />
          </div>
          <div className="custom-column">
            <h2 className="custom-h2">Dein Ziel ist Studienkolleg?</h2>
            <h4 className="custom-h2">Dann wird die App "ZielStk" dein unverzichtbarer Begleiter!</h4>
            <h4 className="custom-h2">Damit kannst du nicht nur das beste Studienkolleg auswählen, sondern dich auch hervorragend auf die Aufnahmeprüfung vorbereiten!</h4>
          </div>
    
          <div id="landing-header">
            <div className="apps">
              <a
                href="https://play.google.com/store/apps/details?id=com.appdroid.develop.zielstk"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={androidLogoImage} width="228" height="65" alt="Android App" />
              </a>
              <a
                href="https://apps.apple.com/us/app/zielstk-studienkolleg/id1480404410"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={iosLogoImage} width="230" height="65" alt="iOS App" />
              </a>
    
              <div className="appscreenc">
                <br />
                <AliceCarousel
                  className="carousel"
                  autoPlay
                  autoPlayInterval={3000}
                >
                  <img src={appscreen} className="sliderimg" alt="App Screen" />
                  <img src={appscreen1} className="sliderimg" alt="App Screen" />
                  <img src={appscreen2} className="sliderimg" alt="App Screen" />
                  <img src={appscreen3} className="sliderimg" alt="App Screen" />
                  <img src={appscreen4} className="sliderimg" alt="App Screen" />
                  <img src={appscreen5} className="sliderimg" alt="App Screen" />
                  <img src={appscreen6} className="sliderimg" alt="App Screen" />
                  <img src={appscreen7} className="sliderimg" alt="App Screen" />
                </AliceCarousel>
              </div>
            </div>
          </div>
    
          <div className="column">
            <div className="col-lg-offset-3">
              <br />
              <div onClick={this.signInWithGoogle} className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-google">
                <span className="firebaseui-idp-icon-wrapper">
                  <img className="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" />
                </span>
                <span className="firebaseui-idp-text" style={{ color: '#3c4043' }}>Sign in with Google</span>
                <span className="firebaseui-idp-text firebaseui-idp-text-short">Google</span>
              </div>
              <div id="firebaseui-auth-container"></div>
            </div>
            <h2 className="custom-h4">
              Registriere dich und beginne sofort mit der Vorbereitung!
            </h2>
            <br />
          </div>
    
          <div className="column">
            <div className="row">
              <div className="col-lg-8" style={{ marginTop: '20px' }}>
                <ul className="list-group">
                  <li className="list-group-item" style={{ background: '#BBDBF3' }}>
                    <h4 className="custom-h2">
                      {totalNumber} Aufgaben zu {themesNumber} Themen
                    </h4>
                  </li>
                  <li className="list-group-item" style={{ background: '#ffeb68' }}>
                    <h4 className="custom-h2">
                      {examNumber} umformulierte Aufgaben aus vergangenen Aufnahmeprüfungen in verschiedene Studienkollegs
                    </h4>
                  </li>
                  <li className="list-group-item" style={{ background: '#BBDBF3' }}>
                    <h4 className="custom-h2">Flexibles Bezahlsystem: Zahle nur für das, was du brauchst!</h4>
                  </li>
                  <li className="list-group-item" style={{ background: '#ffeb68' }}>
                    <h4 className="custom-h2">Eine Fülle interessanter und wichtiger Informationen ist frei zugänglich!</h4>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <img
                  src={phoneStock}
                  className="img-thumbnail rounded float-left"
                  width="100%"
                  height="50%"
                  alt="Phone Stock"
                />
              </div>
            </div>
          </div>
          <hr />
    
          <div>
            <Footer />
          </div>
        </div>
      );
    }

    return (
      <div id="main-div-block">
        <div>
          <Header />
        </div>
        <div className="container">
          <div className="row profile">
            <BrowserRouter>
              <>
                <Menu
                  imageUrl={getAuth().currentUser.photoURL}
                  displayName={getAuth().currentUser.displayName}
                  userEmail={getAuth().currentUser.email}
                  auth={this.Logout}
                />
                <Routes>
                  <Route
                    path="/list"
                    element={<PurchaseList user={getAuth().currentUser} />}
                  />
                  <Route path="/comp" element={<Completion />} />
                  <Route path="/shop" element={<ShopItem />} />
                  <Route path="/cart" element={<Kart />} />
                  <Route path="/support" element={<SupportForm />} />
                  <Route
                    path="/"
                    element={<Navigate to="/shop" replace />} // Перенаправление после входа
                  />
                </Routes>
              </>
            </BrowserRouter>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}







  

