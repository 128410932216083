import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const Completion = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (!user) {
        navigate('/'); // Перенаправление на страницу логина, если пользователь не аутентифицирован
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  const handleButtonClick = () => {
    navigate('/list');
  };

  return (
    <div className="col-md-6 mb-2 mt-5">
      <div className="message">
        <h1><b>Спасибо за покупку!</b></h1>
      </div>
      <div className="profile-userbuttons">
        <button className="btn btn-main-md btn-primary" onClick={handleButtonClick}>
          Мои покупки
        </button>
      </div>
    </div>
  );
};

export default Completion;

  