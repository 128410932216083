import React, { useState } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import vkIcon from '../../images/social_media_icon_vk.png';
import fbIcon from '../../images/social_media_icon_fb.png';
import instaIcon from '../../images/social_media_icon_insta.png';
import youtubeIcon from '../../images/social_media_icon_youtube.png';
import loaderIcon from '../../images/pacman-loader.gif';

const auth = getAuth();

const SupportForm = () => {
  const [sendingMessage, setSendingMessage] = useState(false);
  const [infoMessage, setInfoMessage] = useState(null);
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');

  const clearFieldValues = () => {
    setName('');
    setLastname('');
    setEmail('');
    setText('');
  };

  const sendEmail = async (event) => {
    event.preventDefault();

    if (name === '' || lastname === '' || email === '' || text === '') {
      alert('Необходимо заполнить все поля');
      return;
    }

    setSendingMessage(true);

    try {
      const userId = auth.currentUser.uid;
      const response = await axios.post('https://us-central1-ziel-studienkolleg.cloudfunctions.net/api/sendmail', {
        firstname: name,
        secondname: lastname,
        useremail: email,
        text: text,
        userid: userId
      });

      console.log(response.data);
      setSendingMessage(false);
      setInfoMessage("Сообщение успешно отправлено");
      clearFieldValues();
    } catch (error) {
      console.error(error);
      setSendingMessage(false);
      setInfoMessage("Произошла ошибка при отправке");
      clearFieldValues();
    }
  };

  return (
    <div className="col-md-9">
      <div className="profile-content" id="main">
        <div>
          <h3>Support kontaktieren</h3>

          <div className="card" style={{ outline: '1px solid silver', paddingBottom: '15px' }}>
            <h5 className="card-header" style={{ backgroundColor: '#868e96', padding: '20px', color: 'white' }}>
              Sie können uns auch über die folgenden Ressourcen kontaktieren:
            </h5>
            <div className="card-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-3">
                    <a href="https://vk.com/zielstudienkolleg">
                      <img src={vkIcon} alt="vk" width="50" height="50" className="rounded-circle" />
                    </a><br />
                  </div>
                  <div className="col-lg-3">
                    <a href="https://www.facebook.com/zielstudienkolleg">
                      <img src={fbIcon} alt="fb" width="50" height="50" className="rounded-circle" />
                    </a><br />
                  </div>
                  <div className="col-lg-3">
                    <a href="https://www.instagram.com/zielstudienkolleg/">
                      <img src={instaIcon} alt="insta" width="50" height="50" className="rounded-circle" />
                    </a><br />
                  </div>
                  <div className="col-lg-3">
                    <a href="https://www.youtube.com/channel/UCbEhG_fiHO_94OlFi1FWaXQ">
                      <img src={youtubeIcon} alt="youtube" width="50" height="50" className="rounded-circle" />
                    </a><br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />

          <form onSubmit={sendEmail}>
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                id="fname"
                name="firstname"
                placeholder={auth.currentUser.displayName}
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Nachname</label>
              <input
                type="text"
                id="lname"
                name="lastname"
                placeholder="Ihr Nachname.."
                className="form-control"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder={auth.currentUser.email}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Beschreiben Sie das Problem:</label>
              <textarea
                id="subject"
                name="subject"
                placeholder="Beschreiben Sie das Problem.."
                className="form-control"
                rows="5"
                value={text}
                onChange={(e) => setText(e.target.value)}
              ></textarea>
            </div>
            <button type="submit" className="btn btn-success">Problem melden</button>
          </form>
          {infoMessage && <h4><br />{infoMessage}</h4>}
          {sendingMessage && <center><img src={loaderIcon} alt="spinner" /></center>}
        </div>
      </div>
    </div>
);

};

export default SupportForm;
