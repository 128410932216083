import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const YookassaWrapper = ({ amount, description, items }) => {
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  const db = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    const createPayment = async () => {
      try {
        const response = await axios.post('https://us-central1-ziel-studienkolleg.cloudfunctions.net/createPayment/create-payment', {
          amount: parseFloat(amount), 
          description: description,
          userId: auth.currentUser.uid,
          items: items // Добавляем items в запрос
        });

        console.log('Response from server:', response.data);
        setPaymentUrl(response.data.confirmation.confirmation_url);

        await handleFirebase(response.data.id);
      } catch (error) {
        console.error("Error creating payment:", error);
      } finally {
        setLoading(false);
      }
    };

    createPayment();
  }, [amount, description, items]);

  const handleFirebase = async (paymentId) => {
    try {
      const userId = auth.currentUser.uid;
      const receiptRef = doc(db, 'Users', userId, 'Receipts', paymentId);

      const receiptData = {
        Amount: amount,
        Currency: 'EUR',
        UserId: userId,
        Promo: description || null,
        Items: items || null,
        Gateway: "yookassa",
        Status: 100,
        PurchaseDate: new Date(),
        Successful: false,
      };

      await setDoc(receiptRef, receiptData);

      console.log('Receipt added to Firebase');
    } catch (firebaseError) {
      console.error("Error updating Firebase:", firebaseError);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <a href={paymentUrl} target="_blank" rel="noopener noreferrer">
        Перейти к оплате
      </a>
    </div>
  );
};

export default YookassaWrapper;





