// Footer.js
import React from 'react';
import facebook from '../../images/social_media_icon_fb.png';
import vk from '../../images/social_media_icon_vk.png';
import insta from '../../images/social_media_icon_insta.png';
import youtube from '../../images/social_media_icon_youtube.png';

const Footer = () => (
  <div id="landing-footer" className="footer">
    <h4 className="custom-h2">© Ivan Khokhryakov und Nadezhda Provotorova</h4>
    <div className="social">
      <a href="https://vk.com/zielstudienkolleg" target="_blank" rel="noopener noreferrer">
        <img src={vk} width="50%" height="80%" alt="VK" />
      </a>
    </div>
    <div className="social">
      <a href="https://www.facebook.com/zielstudienkolleg/" target="_blank" rel="noopener noreferrer">
        <img src={facebook} width="50%" height="80%" alt="Facebook" />
      </a>
    </div>
    <div className="social">
      <a href="https://instagram.com/zielstudienkolleg" target="_blank" rel="noopener noreferrer">
        <img src={insta} width="50%" height="80%" alt="Instagram" />
      </a>
    </div>
    <div className="social">
      <a href="https://www.youtube.com/channel/UCbEhG_fiHO_94OlFi1FWaXQ" target="_blank" rel="noopener noreferrer">
        <img src={youtube} width="50%" height="80%" alt="YouTube" />
      </a>
    </div>
  </div>
);




export default Footer;

