import React, { Component } from 'react';

import  ConnectUI  from './components/SignIn/Loginform'



class App extends Component {
  
 
  render() {
    return (
      <div >

        <ConnectUI />
       
      </div>
    );
  }
}

export default App;
