
import FirebaseSet from '../src/FirebaseInit';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);