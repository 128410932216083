import React, { useState, useEffect } from 'react';
import '../../index.css';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import spinner from '../../images/pacman-loader.gif';

const db = getFirestore();

const PurchaseList = ({ user, collectionName = "Purchases" }) => {
  const [purchases, setPurchases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Users", user.uid, collectionName));
        if (!querySnapshot.empty) {
          const items = querySnapshot.docs.map(doc => ({
            Exam: doc.id,
            Data: Object.entries(doc.data())
          }));
          setPurchases(items);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user.uid, collectionName]);

  const formatDate = (date) => {
    const d = new Date(date);
    const month = '' + (d.getMonth() + 1);
    const day = '' + d.getDate();
    const year = d.getFullYear();

    return `${year}-${month.length < 2 ? '0' + month : month}-${day.length < 2 ? '0' + day : day}`;
  };

  return (
    <div className="col-md-9">
      <div className="profile-content">
        <div>
          <h1>Ваши покупки</h1>
          <hr />
          {isLoading ? (
            <center><img src={spinner} alt="Loading..." /></center>
          ) : (
            <div className="list-group" id="listview">
              {purchases.length !== 0 ? (
                purchases.map((val) => (
                  <div key={val.Exam} className="list-group-item list-group-item-action flex-column align-items-start">
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="text-primary mb-1">{val.Exam}</h5>
                    </div>
                    <div>
                      {val.Data.map(([key, value]) => (
                        <ul key={key}>
                          <li>
                            <small>
                              <b>{key}</b>
                              <p className="text-danger">
                                Истекает: <u>{formatDate(value.toDate())}</u>
                              </p>
                            </small>
                          </li>
                        </ul>
                      ))}
                    </div>
                  </div>
                ))
              ) : (
                <h1>У вас нет покупок</h1>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchaseList;

