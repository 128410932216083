import React from 'react';
import headerImage from '../../images/header_logo.png';
import CookieConsent from './Cookie';

const Header = () => (
  <div id="landing-header">
    <div className="row">
      <img src={headerImage} className="custom-img" alt="Header" />
    </div>
    <CookieConsent />
  </div>
);

export default Header;

